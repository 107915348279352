import { createGlobalStyle } from 'styled-components';
import { backgroundColorDark, textDark } from './UI/variables';

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  text-decoration: none;
  background-color: ${backgroundColorDark};
  color: ${textDark};
  }
`;
